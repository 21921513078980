import type { LinksFunction, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { useFetcher, useLoaderData, useNavigate } from "@remix-run/react";
import { useEffect, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Product } from "~types/api/product.types";

import { ButtonOld } from "~/components/ButtonOld";
import { CreateCartModal } from "~/components/CreateCartModal";
import { ErrorComponent } from "~/components/ErrorComponent";
import { HtmlParser } from "~/components/HtmlParser";
import { Image } from "~/components/Image";
import { Link } from "~/components/Link";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { ProductCard } from "~/components/ProductCard";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DialogIcon,
  EyeIcon,
  PlusIcon,
  QuoteIcon,
  TrashIcon,
  UserIcon,
} from "~/components/svg/icons";
import { Wrapper } from "~/components/ui/wrapper";
import { expertiseData, reviewsData } from "~/config/home-page";
import { useManagedFetcher } from "~/hooks/use-managed-fetcher";
import { getCarts } from "~/models/cart.server";
import { getProducts } from "~/models/product.server";
import { getSession } from "~/models/session.server";
import { getSlides } from "~/models/slides.server";
import { useRootMatchData } from "~/root";
import type { CartsActions } from "~/routes/_protected.account.carts";
import styles from "~/styles/HomePage.css?url";
import { analytics } from "~/utils/analytics";
import { cn } from "~/utils/classnames";
import { formatDateToDDMMYYYY } from "~/utils/dates";
import { handleError } from "~/utils/errors.server";
import { getSeoMeta } from "~/utils/seo";

export const meta: MetaFunction = (metaArgs) => {
  const rootLoaderData = metaArgs?.matches?.find((match) => match.id === "root")?.data as any;
  const appUrl: string | null = rootLoaderData?.ENV?.VITE_APP_URL || null;

  return getSeoMeta(metaArgs, {
    title: `Powr Connect : Fournisseur en photovoltaïque et panneaux solaires`,
    description: `Découvrez nos solutions photovoltaïques pour professionnels:  modules et panneaux photovoltaïques, onduleurs solaires, systèmes de fixation, batteries et accessoires`,
    structuredData: {
      "@type": "WebSite",
      name: "Powr Connect",
      potentialAction: {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${appUrl}/recherche?search={search_term_string}`,
        },
        "query-input": "required name=search_term_string",
      },
    },
  });
};

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: styles }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    const session = await getSession({ request });
    const activeContactId = session.get("activeContactId") || null;

    const [comparator, products, cartsResponse, slides] = await Promise.all([
      session.get("comparator") || null,
      getProducts({
        request,
        homeDisplay: true,
      }),
      activeContactId
        ? getCarts({
            isAffair: true,
            sortParam: "createdAt_DESC",
            withoutParent: true,
            itemsPerPage: 3,
            request,
          })
        : null,
      getSlides({ request, page: 1, itemsPerPage: 10000 }),
    ]);

    const carts = cartsResponse?.data || [];

    return json({
      activeContactId,
      carts,
      products: products.data,
      slides: slides.data,
      comparator,
    });
  } catch (error) {
    return handleError(error, {
      activeContactId: null,
      carts: null,
      products: null,
      slides: null,
      comparator: null,
    });
  }
};

const HomePage = () => {
  const data = useLoaderData<typeof loader>();
  const { activeContactId, carts, products, comparator, slides } = data;
  const rootData = useRootMatchData();

  const navigate = useNavigate();

  const [isCreateCartModalOpen, setCreateCartModalOpen] = useState(false);

  const cartsFetcher = useFetcher<CartsActions>();
  const [isCartsFetcherLoading] = useManagedFetcher({
    fetcher: cartsFetcher,
  });

  useEffect(() => {
    if (
      cartsFetcher.state === "loading" &&
      cartsFetcher.data &&
      cartsFetcher.formData?.get("_action") === "changeCart"
    ) {
      navigate("/cart");
    }
  }, [cartsFetcher, navigate]);

  return (
    <Wrapper className="HomePage">
      {isCreateCartModalOpen ? (
        <CreateCartModal
          isOpen={isCreateCartModalOpen}
          onClose={() => setCreateCartModalOpen(false)}
        />
      ) : null}

      <h1 className="HomePage-title">
        Fournisseur n°1 en solutions photovoltaïques pour les professionnels
      </h1>
      <section className="HomePage-hero">
        <div className="HomePage-hero-carousel">
          <ClientOnly fallback={null}>
            {() => (
              <Swiper
                modules={[Autoplay, Pagination]}
                loop={true}
                pagination={{ type: "progressbar" }}
                grabCursor={true}
                autoplay={{
                  delay: 10000,
                  stopOnLastSlide: false,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                }}
              >
                {slides
                  ?.filter((slide) =>
                    activeContactId ? slide.userType === "logged" : slide.userType === "anonymous"
                  )
                  .filter((slide) => !!slide.image)
                  .sort((a, b) => a.position - b.position)
                  .map((slide) => (
                    <SwiperSlide key={slide.id}>
                      <Image
                        src={slide.image}
                        alt={slide.title}
                        className="HomePage-hero-carousel-image"
                      />
                      <div className="HomePage-hero-carousel-content">
                        {/* {slide.title && slide.title !== "." ? (
                          <h2 className="HomePage-hero-carousel-title">{slide.title}</h2>
                        ) : null} */}
                        {slide.buttonText && slide.url ? (
                          <ButtonOld
                            as="a"
                            href={slide.url}
                            target={slide.target}
                            label={slide.buttonText}
                            color="white"
                            className="HomePage-hero-carousel-button"
                            IconComponent={<ArrowRightIcon />}
                            onClick={() =>
                              analytics.event({
                                action: "click",
                                data: {
                                  event_category: "slider-accueil",
                                  event_label: slide?.title || "",
                                },
                              })
                            }
                          />
                        ) : null}
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </ClientOnly>
        </div>
        {activeContactId ? (
          <div
            className={cn(
              "HomePage-hero-account",
              isCartsFetcherLoading && "HomePage-hero-account--loading"
            )}
          >
            {isCartsFetcherLoading ? (
              <LoadingSpinner className="HomePage-hero-account-loadingSpinner svgIcon" />
            ) : null}
            <h2 className="HomePage-hero-account-title">Mes affaires</h2>
            {carts && carts.length > 0 ? (
              <div className="HomePage-hero-account-business">
                {carts.map((cart) => (
                  <cartsFetcher.Form key={cart.id} method="post" action="/account/carts">
                    <div className="HomePage-hero-account-business-item">
                      <div className="HomePage-hero-account-business-item-texts">
                        <p className="HomePage-hero-account-business-item-texts-name">
                          {cart.label.toString() || cart.id.toString() || ""}
                        </p>
                        <p className="HomePage-hero-account-business-item-texts-date">
                          {formatDateToDDMMYYYY(cart.createdAt)}
                        </p>
                      </div>
                      <div className="HomePage-hero-account-business-item-buttons">
                        <input type="hidden" name="newActiveCartId" value={cart.id} />
                        <input type="hidden" name="contactId" value={activeContactId || ""} />
                        <ButtonOld
                          IconComponent={<EyeIcon />}
                          type="submit"
                          name="_action"
                          value="changeCart"
                          className="HomePage-hero-account-business-item-buttons-button"
                        />
                        <input type="hidden" name="cartToDeleteId" value={cart.id} />
                        <ButtonOld
                          IconComponent={<TrashIcon />}
                          name="_action"
                          value="deleteCart"
                          type="submit"
                          className="HomePage-hero-account-business-item-buttons-button"
                        />
                      </div>
                    </div>
                  </cartsFetcher.Form>
                ))}
              </div>
            ) : (
              <span className="HomePage-hero-account-info">
                Vous n&apos;avez pas d&apos;affaire en cours
              </span>
            )}
            <div className="HomePage-hero-account-buttonGroup">
              <ButtonOld
                label="Voir mes affaires"
                IconComponent={<EyeIcon />}
                uppercase={true}
                href="/account/carts"
                as="a"
                className="HomePage-hero-account-button"
              />
              <ButtonOld
                label="Créer une affaire"
                IconComponent={<PlusIcon />}
                uppercase={true}
                color="white"
                onClick={() => setCreateCartModalOpen(true)}
                className="HomePage-hero-account-button"
              />
            </div>
          </div>
        ) : (
          <div className="HomePage-hero-account">
            <h2 className="HomePage-hero-account-title">Devenir client</h2>

            <ul className="HomePage-hero-account-list">
              <li>Un confort d&apos;achat pour vous</li>
              <li>Une réelle autonomie</li>
              <li>Un service (toujours) sur-mesure</li>
              <li>Notre stock en direct</li>
            </ul>
            <div className="HomePage-hero-account-bottom">
              <p className="HomePage-hero-account-info">Réservé aux professionnels</p>
              <ButtonOld
                as="a"
                label="Créer un compte"
                IconComponent={<UserIcon />}
                className="HomePage-hero-account-button"
                href="/inscription"
              />
            </div>
          </div>
        )}
      </section>

      <section className="HomePage-products">
        <h2 className="HomePage-products-title">Produits photovoltaïques à la une</h2>

        <div className="HomePage-products-carousel">
          <button className="swiper-products-button-prev" aria-label="Slide précédente">
            <ChevronLeftIcon className="svgIcon" />
          </button>
          <ClientOnly fallback={null}>
            {() => (
              <Swiper
                modules={[Navigation, Autoplay]}
                watchSlidesProgress={true}
                navigation={{
                  enabled: true,
                  nextEl: ".swiper-products-button-next",
                  prevEl: ".swiper-products-button-prev",
                }}
                loop={true}
                centeredSlides={false}
                grabCursor={true}
                slidesPerView={1}
                spaceBetween={16}
                autoplay={{
                  delay: 10000,
                  stopOnLastSlide: false,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  880: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                  },
                  1250: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                  },
                }}
              >
                {products?.map((product: Product) => (
                  <SwiperSlide key={product.id}>
                    <ProductCard
                      product={product}
                      favorites={rootData?.favorites || null}
                      comparator={comparator}
                      activeContactId={activeContactId}
                      contact={rootData?.contact || null}
                      data-testid="home-product-card"
                    />
                  </SwiperSlide>
                ))}
                {products?.map((product: Product) => (
                  <SwiperSlide key={product.id + "-2"}>
                    <ProductCard
                      product={product}
                      favorites={rootData?.favorites || null}
                      comparator={comparator}
                      activeContactId={activeContactId}
                      contact={rootData?.contact || null}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </ClientOnly>
          <button className="swiper-products-button-next" aria-label="Slide suivante">
            <ChevronRightIcon className="svgIcon" />
          </button>
        </div>
      </section>

      <section className="HomePage-reviews">
        <div className="HomePage-reviews-leftCol">
          <div className="HomePage-reviews-leftCol-header">
            <h2 className="HomePage-reviews-leftCol-header-title">Ils parlent de nous...</h2>
            <div className="HomePage-reviews-leftCol-header-buttonsContainer">
              <button
                className="HomePage-reviews-leftCol-header-button swiper-button-prev-custom"
                aria-label="Slide précédente"
              >
                <ArrowLeftIcon className="HomePage-reviews-leftCol-header-button-icon svgIcon" />
              </button>

              <button
                aria-label="Slide suivante"
                className="HomePage-reviews-leftCol-header-button swiper-button-next-custom"
              >
                <ArrowRightIcon className="HomePage-reviews-leftCol-header-button-icon svgIcon" />
              </button>
            </div>
          </div>
          <div className="HomePage-reviews-leftCol-carousel">
            <ClientOnly fallback={null}>
              {() => (
                <Swiper
                  modules={[Autoplay, Navigation]}
                  loop={true}
                  grabCursor={true}
                  /* autoplay={{
                    delay: 15000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                  }} */
                  navigation={{
                    enabled: true,
                    nextEl: ".swiper-button-next-custom",
                    prevEl: ".swiper-button-prev-custom",
                  }}
                >
                  {reviewsData.map((review) => (
                    <SwiperSlide key={review.title}>
                      <div className="HomePage-reviews-leftCol-carousel-content">
                        <Image
                          src={review.brandImageUrl.replace(
                            "<API_ROOT_URL>",
                            window?.ENV?.API_ROOT_URL || ""
                          )}
                          alt={review.title}
                          className="HomePage-reviews-leftCol-carousel-image"
                        />
                        <div className="HomePage-reviews-leftCol-carousel-main">
                          <QuoteIcon className="HomePage-reviews-leftCol-carousel-icon svgIcon" />
                          <p className="HomePage-reviews-leftCol-carousel-title">{review.title}</p>
                          <p className="HomePage-reviews-leftCol-carousel-subtitle">
                            {review.subtitle}
                          </p>
                          <HtmlParser
                            as="p"
                            html={review.text}
                            className="HomePage-reviews-leftCol-carousel-text"
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </ClientOnly>
          </div>
        </div>
        <div className="HomePage-reviews-rightCol">
          <span className="HomePage-reviews-rightCol-title">
            Une activité plus responsable avec vous !
          </span>
          <span className="HomePage-reviews-rightCol-text">
            POwR Connect, leader français dans la distribution de matériels photovoltaïques en
            France, a participé à une opération de plantation de 5000 arbres en Amazonie, à laquelle
            nombre d&apos;entre vous avez participé.
          </span>
          <ButtonOld
            as="a"
            href="/a-propos/developpement-durable"
            variant="outline"
            color="white"
            label="EN SAVOIR PLUS"
            IconComponent={<DialogIcon />}
          />
        </div>
      </section>
      <section className="HomePage-expertise">
        <h2 className="HomePage-expertise-title">Nos expertises à votre service</h2>
        <div className="HomePage-expertise-grid">
          {expertiseData.map((item) => (
            <Link
              to="/a-propos/nos-expertises"
              className="HomePage-expertise-item"
              key={item.label}
            >
              <img src={item.image} alt={item.label} className="HomePage-expertise-item-image" />
              <p className="HomePage-expertise-item-label">{item.label}</p>
            </Link>
          ))}
        </div>
      </section>
      <section className="HomePage-about">
        <h2 className="HomePage-about-title">
          Fournisseur et grossiste en panneaux solaires et onduleurs
        </h2>
        <p className="HomePage-about-text">
          Chez Powr Connect, nous nous engageons à fournir des solutions d&apos;énergie solaire de
          haute qualité pour répondre aux besoins croissants en matière de durabilité et
          d&apos;efficacité énergétique. En tant que fournisseur et grossiste en panneaux solaires
          et en onduleurs, nous offrons une gamme complète de produits et services pour les
          entreprises et les collectivités.
        </p>
      </section>
    </Wrapper>
  );
};

export default HomePage;

export const ErrorBoundary = () => {
  return <ErrorComponent />;
};
